/* Importando (extend) para definir reglas personalizadas y (localize) para definir idiomas de forma local */
import { extend, localize } from 'vee-validate'

/* Importando (reglas generales) */
import {
  required as rule_required, // No modificar
  email as rule_email, // No modificar
  min as rule_min, // No modificar
  max as rule_max, // No modificar
  confirmed as rule_confirmed, // No modificar
  regex as rule_regex, // No modificar
  between as rule_between, // No modificar
  alpha as rule_alpha, // No modificar
  integer as rule_integer, // No modificar
  digits as rule_digits, // No modificar
  alpha_dash as rule_alpha_dash, // No modificar
  alpha_num as rule_alpha_num, // No modificar
  length as rule_length, // No modificar
} from 'vee-validate/dist/rules'

/* Importando archivos que incluyen los (mensajes de las reglas generales por idioma local instalado) */
// import en from 'vee-validate/dist/locale/en.json'
// import es from 'vee-validate/dist/locale/es.json'
import en from './en.json'
import es from './es.json'

/* Importando expresiones regulares a extender */
// eslint-disable-next-line object-curly-newline
import {
  validatorPositive, validatorUrlValidator, validatorPassword, validatorCreditCard, validatorNameRegex,
  validatorZipRegex, validatorAddressRegex, validatorConfirmPassword, validatorClubNameRegex, validatorAlphaNumSpacesRegex,
  validatorTagsRegex,
} from './validators'

/* Exportando reglas generales */
export const required = extend('required', rule_required)

export const email = extend('email', rule_email)

export const min = extend('min', rule_min)
export const max = extend('max', rule_max)

export const confirmed = extend('confirmed', rule_confirmed)

export const regex = extend('regex', rule_regex)

export const between = extend('between', rule_between)

export const alpha = extend('alpha', rule_alpha)

export const integer = extend('integer', rule_integer)

export const digits = extend('digits', rule_digits)

export const alphaDash = extend('alpha-dash', rule_alpha_dash)

export const alphaNum = extend('alpha-num', rule_alpha_num)

export const length = extend('length', rule_length)

/* Exportando reglas generales con expresiones regulares personalizadas */
export const positive = extend('positive', {
  validate: validatorPositive,
  message: 'Please enter positive number!',
})

export const credit = extend('credit-card', {
  validate: validatorCreditCard,
  message: 'It is not valid credit card!',
})

export const password = extend('password', {
  validate: validatorPassword,
})

export const url = extend('url', {
  validate: validatorUrlValidator,
  message: 'URL is invalid',
})

/* Definiendo reglas personalizadas */
export const nameRegex = extend('nameRegex', {
  validate: validatorNameRegex,
})

export const zipRegex = extend('zipRegex', {
  validate: validatorZipRegex,
})

export const addressRegex = extend('addressRegex', {
  validate: validatorAddressRegex,
})

export const clubNameRegex = extend('clubNameRegex', {
  validate: validatorClubNameRegex,
})

export const confirmPassword = extend('confirmPassword', {
  params: ['target'],
  validate: validatorConfirmPassword,
})
export const alphaNumSpacesRegex = extend('alphaNumSpacesRegex', {
  validate: validatorAlphaNumSpacesRegex,
})
export const tagsRegex = extend('tagsRegex', {
  validate: validatorTagsRegex,
})
/* Definiendo idiomas locales [Ingles, Español] */
localize({
  // i18n,
  /* Ingles */
  en: {
    messages: en.messages,
    names: {
      /* _-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_- [Campos - Reutilizables] */
      email: 'Email',
      password: 'Password',
      code: 'Code',
      country: 'Country',
      city: 'City',
      administrator: 'Administrator',
      category: 'Category',
      validity: 'Validity',
      status: 'Status',
      jobPosition: 'Job',
      name: 'Name',
      firstName: 'First name',
      gender: 'Gender',
      birthday: 'Birthday',
      placeBirth: 'Place of birth',
      lastDegreeStudies: 'Last degree of studies',
      cellPhone: 'Cell phone',
      address: 'Address',
      postalCode: 'Zip code',
      state: 'State',
      idNumber: 'Identification number',
      nationality: 'Nationality',
      expiration: 'Expiration',
      visaNumber: 'Visa number',
      typeOfVisa: 'Type of visa',
      endDate: 'Final date',
      initDate: 'Initial date',
      team: 'Team',
      countryBirth: 'country of birth',
      stateBirth: 'birth state',
      title: 'Title',
      tags: 'Tags',
      language: 'Language',
      message: 'Message',
      fullName: 'Full name',
      license: 'License',
      role: 'Role',
      payment: 'Payment',
      methodPayment: 'Method of payment',
      periodicity: 'Periodicity',
      representative: 'Representative',
      jurisdiction: 'Jurisdiction',
      numberTMS: 'NumberTMS',
      typeOperation: 'Type of operation',
      dueDate: 'Due date',
      color: 'Color',
      mobilePhone: 'Mobile phone',
      phone: 'Phone',
      userFirstName: 'First name',
      userLastName: 'Last name',
      userSecondLastName: 'Second last name',
      passport: 'Passport',
      observations: 'Observations',
      document: 'Document',
      url: 'URL',
      excludeTo: 'Exclude to:',
      sendTo: 'Send to:',

      /* _-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_- [Campos - Crud Clubes] */
      sportsClubName: 'Sports club name',
      denominationOrTradeName: 'Denomination or Trade Name',
      /* _-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_- [Campos - Crud Números de Instrumento] */
      instrumentDate: 'Instrument date',
      instrumentNumber: 'Instrument number',
      countryOfNotary: 'Country of notary',
      stateOfNotary: 'State of notary',
      notaryName: 'Notary name',
      notaryNumber: 'Notary number',
      /* _-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_- [Campos - Crud Sociedades] */
      incorporationDate: 'Incorporation date',
      durationSociety: 'Duration of the society',
      corporatePurpose: 'Corporate purpose',
      wayToManage: 'Way to manage',
      anotherWayToManage: 'Another way to manage',
      administrativeStructure: 'Administrative structure',
      commissioner: 'Commissioner for oaths',
      partnerName: 'Partner name',
      percentage: 'Percentage',
      shareNumber: 'Share number',
      /* _-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_- [Campos - Crud Asambleas] */
      assemblyDate: 'Assembly date',
      agreementsOfTheAssembly: 'Agreements taken in the assembly',
      /* _-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_- [Campos - Crud Apoderados] */
      instrumentNumbers: 'Instrument numbers',
      representativeIn: 'Representative',
      representatives: 'Representatives',
      newRepresentative: 'New representative',
      newRepresentatives: 'New representatives',
      powersGranted: 'Powers granted',
      validityPeriod: 'Validity period of power of attorney',
      specialPowers: 'Special powers',
      /* _-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_- [Campos - Crud Registro de Marca] */
      requestType: 'Request type',
      trademarkType: 'Trademark type',
      classNumber: 'Class number',
      classDescription: 'Class description',
      denomination: 'Denomination',
      registerNumber: 'Register number',
      dateOfConcession: 'Date of concession',
      effectiveDate: 'Effective date',
      applicationForTheRegistration: 'Application for the registration',
      holderName: 'Holder name',
      representativeImageOfTheTrademark: 'Representative image of the trademark',
      /* _-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_- [Campos - Crud Registro de Obra] */
      typeOfLiteraryOrArtisticWork: 'Type of literary or artistic work',
      dateOfRequest: 'Date of request',
      dateOfConcessionL: 'Date of concession',
      titleOfLiteraryOrArtisticWork: 'Title of literary or artistic work',
      creatorAndOrDesigner: 'Creator and/or designer',
      applicationFileL: 'Application file',
      contractFile: 'Contract file',
      /* _-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_- [Campos - Crud Convenios] */
      addressForNotifications: 'Address for notifications',
      agreements: 'Agreements',
      agreementsData: 'Agreement data',
      alternateEmail: 'Alternate email',
      alternatePhone: 'Alternate phone',
      assignor: 'Assignor Name',
      costOfAssignmentOfRights: 'Cost of assignment of rights',
      dateOfAssignmentOfRights: 'Date of assignment of rights',
      dossierNumber: 'Dossier number',
      otherBenefitsOrObservations: 'Other benefits or observations',
      paymentConditions: 'Payment conditions',
      percentageOfAssignmentOfRights: 'Percentage of assignment of rights',
      personality: 'Personality',
      trademarkImage: 'Trademark image',
      trademarkToBeAssigned: 'Registration number of the trademark to be assigned',
      /* _-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_- [Campos - Crud Roles] */

      /* _-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_- [Campos - Crud Permisos] */

      /* _-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_- [Campos - Crud Usuarios] */
      oldPassword: 'Old password',
      newPassword: 'New password',
      newPasswordRet: 'New password',
      /* _-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_- [Campos - Crud Integrantes] */
      registrationFederation: 'Registration in the federation',
      idFifa: 'ID FIFA',
      dateAdmission: 'Date of admission',
      jerseyNumber: 'Jersey number',
      passportNumber: 'Number passport',
      transferContractInitDate: 'Initial date',
      transferContractEndDate: 'Final date',
      transferOperationInitDate: 'Initial date',
      transferOperationEndDate: 'Final date',
      transferContractSignatureDate: 'Signature date',
      transferNameClub: 'Club name',
      transferPercentage: 'Percentage',
      transferDescriptionConditions: 'Transfer conditions',
      transferRepresentative: 'Representative',
      transferFixedQuantity: 'Fixed quantity',
      transferVariableQuantity: 'Variable quantity',
      transferMethodPayment: 'Payment method',
      transferPeriodicity: 'Periodicity',
      transferPeriodicityOther: 'Periodicity',
      transferPenaltyAmount: 'Amount of penalty',

      // Address
      transferAddress: 'Address',
      transferPostalCode: 'Postal Code',
      transferCountry: 'Country',
      transferState: 'state',
      transferEmail: 'Email',
      transferJurisdiction: 'Jurisdiction',
      transferJurisdictionOther: 'Jurisdiction',
      transferLegislation: 'Legislation',

      // transferAgent
      transferAgentName: 'Name',
      transferAgentLicence: 'License number',
      transferAgentRole: 'Activity or role',
      transferAgentPayment: 'Payment',
      transferAgentMethodPayment: 'Payment method',
      transferAgentPeriodicity: 'Periodicity',
      transferAgentPeriodicityOther: 'Periodicity',
      transferAgentInitDate: 'initial date',
      transferAgentEndDate: 'Final date',
      transferAgentRepresentative: 'Representative',

      // general
      generalTypeContract: 'Type of contract',
      generalTypeContractOther: 'Type of contract',
      generalTypeContractTitleOrLicence: 'Title and/or licence',
      generalRepresentative: 'Representative',
      generalContractFreeSignatureDate: 'Signature date',
      generalContractFreeInitDate: 'Initial date',
      generalContractFreeEndDate: 'Final date',

      // Contraprestacion
      generalFixedQuantity: 'Fixed quantity',
      generalVariableQuantity: 'Variable quantity',

      generalIncrease: 'Increase',
      generalIncreaseConditions: 'Increase conditions',
      generalMethodPayment: 'Payment method',
      generalPeriodicity: 'Periodicity',
      generalPeriodicityOther: 'Periodicity',
      generalBonds: 'Bonds',
      generalBenefits: 'Benefits',
      generalCompensationForEarlyTermination: 'Compensation for early termination',
      generalCompensationDetails: 'Compensation details',
      generalPenaltyAmount: 'Amount of penalty',
      generalPenaltyDetails: 'Penalty details',
      generalRescission: 'Rescission',
      generalRescissionDetails: 'Termination details',

      // generalAddress
      generalAddress: 'Address',
      generalPostalCode: 'Postal Code',
      generalCountry: 'Country',
      generalState: 'state',
      generalEmail: 'Email',
      generalJurisdiction: 'Jurisdiction',
      generalJurisdictionOther: 'Jurisdiction',
      generalLegislation: 'Legislation',

      // generalAgent
      generalAgentName: 'Name',
      generalAgentLicence: 'License number',
      generalAgentRole: 'Activity or role',
      generalAgentPayment: 'Payment',
      generalAgentMethodPayment: 'Payment method',
      generalAgentPeriodicity: 'Periodicity',
      generalAgentPeriodicityOther: 'Periodicity',
      generalAgentInitDate: 'Initial date',
      generalAgentEndDate: 'Final date',
      /* _-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_- [Campos - Messages Module] */
      groupName: 'Group name',
      howDoYouWant: 'How do you want to send the message?',
    },
    fields: {
      /* _-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_- [Mensajes Reutilizables] */
      // password: {
      //   password: 'Your {_field_} must contain at least one uppercase, one lowercase, one special character and one digit',
      // },
    },
  },

  /* Español */
  es: {
    messages: es.messages,
    names: {
      /* _-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_- [Campos - Reutilizables] */
      email: 'Correo electrónico',
      password: 'Contraseña',
      code: 'Código',
      country: 'País',
      city: 'Ciudad',
      administrator: 'Administrador',
      category: 'Categoría',
      validity: 'Vigencia',
      status: 'Estatus',
      jobPosition: 'Puesto',
      name: 'Nombre',
      firstName: 'Primer apellido',
      gender: 'Genero',
      birthday: 'Fecha de nacimiento',
      placeBirth: 'Lugar de nacimiento',
      lastDegreeStudies: 'Último grado de estudios',
      cellPhone: 'Celular',
      address: 'Dirección',
      postalCode: 'Código postal',
      state: 'Estado',
      idNumber: 'No. de identificación',
      nationality: 'Nacionalidad',
      expiration: 'Vencimiento',
      visaNumber: 'No. de visa',
      typeOfVisa: 'Tipo de visa',
      endDate: 'Fecha final',
      initDate: 'Fecha inicial',
      team: 'Equipo',
      countryBirth: 'Pais de nacimiento',
      stateBirth: 'Estado de nacimiento',
      title: 'Título',
      tags: 'Tags',
      language: 'Idioma',
      message: 'Mensaje',
      fullName: 'Nombre completo',
      license: 'Licencia',
      role: 'Rol',
      payment: 'Pago',
      methodPayment: 'Forma de pago',
      periodicity: 'Periodicidad',
      representative: 'Representante',
      jurisdiction: 'Jurisdicción',
      numberTMS: 'Número TMS',
      typeOperation: 'Tipo de operación',
      dueDate: 'Fecha de termino',
      color: 'Color',
      mobilePhone: 'Teléfono móvil',
      phone: 'Teléfono',
      userFirstName: 'Nombre',
      userLastName: 'Apellido paterno',
      userSecondLastName: 'Apellido materno',
      passport: 'Pasaporte',
      observations: 'Observaciones',
      document: 'Documento',
      url: 'URL',
      excludeTo: 'Excluir a:',
      sendTo: 'Enviar a:',

      /* _-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_- [Campos - Crud Clubes] */
      sportsClubName: 'Nombre del club deportivo',
      denominationOrTradeName: 'Denominación o Razón Social',
      /* _-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_- [Campos - Crud Números de Instrumento] */
      instrumentDate: 'Fecha del instrumento',
      instrumentNumber: 'Número de instrumento',
      countryOfNotary: 'País del fedatario',
      stateOfNotary: 'Estado del fedatario',
      notaryName: 'Nombre del fedatario',
      notaryNumber: 'Número del fedatario',
      /* _-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_- [Campos - Crud Sociedades] */
      incorporationDate: 'Fecha de constitución',
      durationSociety: 'Duración de la sociedad',
      corporatePurpose: 'Objeto de la sociedad',
      wayToManage: 'Forma de administración',
      anotherWayToManage: 'Otra forma de administrar',
      administrativeStructure: 'Estructura de la administración',
      commissioner: 'Comisario',
      partnerName: 'Nombre del socio',
      percentage: 'Porcentaje',
      shareNumber: 'Número de acciones',
      /* _-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_- [Campos - Crud Asambleas] */
      assemblyDate: 'Fecha de asamblea',
      agreementsOfTheAssembly: 'Acuerdos tomados en la asamblea',
      /* _-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_- [Campos - Crud Apoderados] */
      instrumentNumbers: 'Números de instrumento',
      representativeIn: 'Apoderado',
      representatives: 'Apoderados',
      newRepresentative: 'Nuevo apoderado',
      newRepresentatives: 'Nuevos apoderados',
      powersGranted: 'Poderes otorgados',
      validityPeriod: 'Vigencia del o los poderes',
      specialPowers: 'Poderes especiales',
      /* _-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_- [Campos - Crud Registro de Marca] */
      requestType: 'Tipo de solicitud',
      trademarkType: 'Tipo de marca',
      classNumber: 'Número de la clase',
      classDescription: 'Descripción de la clase',
      denomination: 'Denominación',
      registerNumber: 'Número de registro',
      dateOfConcession: 'Fecha de concesión',
      effectiveDate: 'Fecha de vigencia',
      applicationForTheRegistration: 'Solicitud de registro',
      holderName: 'Nombre del titular',
      representativeImageOfTheTrademark: 'Imagen representativa de la marca',
      /* _-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_- [Campos - Crud Registro de Obra] */
      typeOfLiteraryOrArtisticWork: 'Tipo de obra literaria o artistica',
      dateOfRequest: 'Fecha de solicitud',
      dateOfConcessionL: 'Fecha de concesión',
      titleOfLiteraryOrArtisticWork: 'Título de la obra literaria o artística',
      creatorAndOrDesigner: 'Creador y/o diseñador',
      applicationFileL: 'Expediente de solicitud',
      contractFile: 'Contrato de obra',
      /* _-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_- [Campos - Crud Convenios] */
      addressForNotifications: 'Domicilio para notificaciones',
      agreements: 'Convenios',
      agreementsData: 'Datos del convenio',
      alternateEmail: 'Correo electrónico alternativo',
      alternatePhone: 'Teléfono alternativo',
      assignor: 'Nombre del cedente',
      costOfAssignmentOfRights: 'Costo de la cesión',
      dateOfAssignmentOfRights: 'Fecha de la cesión',
      dossierNumber: 'Número de expediente',
      otherBenefitsOrObservations: 'Otras prestaciones u observaciones',
      paymentConditions: 'Condiciones de pago',
      percentageOfAssignmentOfRights: 'Porcentaje de la cesión',
      personality: 'Personalidad',
      trademarkImage: 'Imagen de la marca registrada',
      trademarkToBeAssigned: 'Número de registro de la marca a ceder',
      /* _-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_- [Campos - Crud Roles] */

      /* _-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_- [Campos - Crud Permisos] */

      /* _-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_- [Campos - Crud Usuarios] */
      oldPassword: 'Contraseña anterior',
      newPassword: 'Nueva contraseña',
      newPasswordRet: 'Nueva contraseña',
      /* _-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_- [Campos - Crud Integrantes] */
      registrationFederation: 'Registro en federación',
      idFifa: 'ID FIFA',
      dateAdmission: 'Fecha de ingreso',
      jerseyNumber: 'Número de camiseta',
      passportNumber: 'No. de pasaporte',
      transferContractInitDate: 'Fecha inicial',
      transferContractEndDate: 'Fecha final',
      transferOperationInitDate: 'Fecha inicial',
      transferOperationEndDate: 'Fecha final',
      transferContractSignatureDate: 'Fecha de firma',
      transferNameClub: 'Nombre del club',
      transferPercentage: 'Porcentaje',
      transferDescriptionConditions: 'condiciones de transferencia',
      transferRepresentative: 'representante o apoderado',
      transferFixedQuantity: 'Cantidad fija',
      transferVariableQuantity: 'Cantidad variable',
      transferMethodPayment: 'Forma de pago',
      transferPeriodicity: 'Periodicidad',
      transferPeriodicityOther: 'Periodicidad',
      transferPenaltyAmount: 'Cantidad de penalización',

      // Address
      transferAddress: 'Dirección',
      transferPostalCode: 'Código postal',
      transferCountry: 'País',
      transferState: 'Estado',
      transferEmail: 'Correo electrónico',
      transferJurisdiction: 'Jurisdicción',
      transferJurisdictionOther: 'Jurisdicción',
      transferLegislation: 'Legislación',

      // transferAgent
      transferAgentName: 'Nombre',
      transferAgentLicence: 'Número de licencia',
      transferAgentRole: 'actividad o rol',
      transferAgentPayment: 'Pago',
      transferAgentMethodPayment: 'Forma de pago',
      transferAgentPeriodicity: 'Periodicidad',
      transferAgentPeriodicityOther: 'Periodicidad',
      transferAgentInitDate: 'fecha inicial',
      transferAgentEndDate: 'fecha final',
      transferAgentRepresentative: 'representante o apoderado',

      // general
      generalTypeContract: 'Tipo de contrato',
      generalTypeContractOther: 'Tipo de contrato',
      generalTypeContractTitleOrLicence: 'Título y/o Cédula',
      generalRepresentative: 'representante o apoderado',
      generalContractFreeSignatureDate: 'Fecha de firma',
      generalContractFreeInitDate: 'Fecha inicial',
      generalContractFreeEndDate: 'Fecha final',

      // Contraprestacion
      generalFixedQuantity: 'Cantidad fija',
      generalVariableQuantity: 'Cantidad variable',

      generalIncrease: 'Incremento',
      generalIncreaseConditions: 'Condiciones de incremento',
      generalMethodPayment: 'Forma de pago',
      generalPeriodicity: 'Periodicidad',
      generalPeriodicityOther: 'Periodicidad',
      generalBonds: 'Bonos',
      generalBenefits: 'Prestaciones',
      generalCompensationForEarlyTermination: 'Indemnización por terminación anticipada',
      generalCompensationDetails: 'Detalles de indemnización',
      generalPenaltyAmount: 'Cantidad de penalización',
      generalPenaltyDetails: 'Detalles de penalización',
      generalRescission: 'Rescisión',
      generalRescissionDetails: 'Detalles de rescisión',

      // generalAddress
      generalAddress: 'Dirección',
      generalPostalCode: 'Código postal',
      generalCountry: 'País',
      generalState: 'Estado',
      generalEmail: 'Correo electrónico',
      generalJurisdiction: 'Jurisdicción',
      generalJurisdictionOther: 'Jurisdicción',
      generalLegislation: 'Legislación',

      // generalAgent
      generalAgentName: 'Nombre',
      generalAgentLicence: 'Número de licencia',
      generalAgentRole: 'actividad o rol',
      generalAgentPayment: 'Pago',
      generalAgentMethodPayment: 'Forma de pago',
      generalAgentPeriodicity: 'Periodicidad',
      generalAgentPeriodicityOther: 'Periodicidad',
      generalAgentInitDate: 'fecha inicial',
      generalAgentEndDate: 'fecha final',
      /* _-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_- [Campos - Messages Module] */
      groupName: 'Nombre del grupo',
      howDoYouWant: '¿Cómo quieres enviar el mensaje?',
    },
    fields: {
      /* _-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_- [Mensajes - Reutilizables] */
      // password: {
      //   password: 'Tu {_field_} debe contener al menos una mayúscula, una minúscula, un carácter especial y un dígito',
      // },
    },
  },
})

// ////////////////////////////////////////////////////////
// NOTE:
// Quasar validation for reference only
// Remove this note once development is finished and make sure to
// to convert all of them in veevalidate version
// ////////////////////////////////////////////////////////

// export const required = (val) => {
//   return (val && val.length > 0) || '*Field is required'
// }

// export const required_obj = (obj) => {
//   if (obj === null || obj === undefined) return '*Field is required'
//   return (Object.entries(obj).length > 0 && obj.constructor === Object) || '*Field is required'
// }

// export const no_blank_spaces_arr = (arr) => {
//   return arr.every(val => (val.trim() && val.trim().length > 0)) || 'Blank Spaces are not allowed'
// }

// export const url = val => {
//   // If blank return
//   if (val === undefined || val === null || val.length === 0) return true

//   // Used
//   // https://stackoverflow.com/questions/4314741/url-regex-validation

//   // Other
//   // https://stackoverflow.com/questions/5717093/check-if-a-javascript-string-is-a-url
//   // https://www.w3resource.com/javascript-exercises/javascript-regexp-exercise-9.php
//   // https://www.geeksforgeeks.org/how-to-validate-url-using-regular-expression-in-javascript/

//   /* eslint-disable no-useless-escape */
//   const re = /^(http[s]?:\/\/){0,1}(www\.){0,1}[a-zA-Z0-9\.\-]+\.[a-zA-Z]{2,5}[\.]{0,1}/
//   /* eslint-enable no-useless-escape */
//   return re.test(val) || 'URL is invalid'
// }

// export const date = val => {

//   // If blank return
//   if (val === undefined || val === null || val.length === 0) return true

//   // https://github.com/quasarframework/quasar/blob/dev/ui/src/utils/patterns.js
//   return /^-?[\d]+\/[0-1]\d\/[0-3]\d$/.test(val) || 'Date is invalid'
// }

// export const max = (val, max) => {

//   // If blank return
//   if (val === undefined || val === null) return true

//   return val.length <= max || `More than ${max} characters are not allowed`
// }

// export const max_arr = (val, max) => {
//   return val.length <= max || `More than ${max} values are not allowed`
// }

// export const min = (val, min) => {

//   // If blank return
//   if (val === undefined || val === null || val.length === 0) return true

//   return val.length >= min || `Minimum ${min} characters are required`
// }

// export const num_range = (val, min, max) => {

//   // If blank return
//   if (val === undefined || val === null || val.length === 0) return true

//   const msg = 'Value is invalid'
//   if (min === null) return val <= max || msg
//   else if (max === null) return val >= min || msg
//   else return (val >= min && val <= max) || msg
// }
