export const validatorPositive = value => {
  if (value >= 0) {
    return true
  }
  return false
}

export const validatorPassword = password => {
  /* eslint-disable no-useless-escape */
  const regExp = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%&*()]).{8,}/
  /* eslint-enable no-useless-escape */
  const validPassword = regExp.test(password)
  return validPassword
}

export const validatorCreditCard = creditnum => {
  /* eslint-disable no-useless-escape */
  const cRegExp = /^(?:3[47][0-9]{13})$/
  /* eslint-enable no-useless-escape */
  const validCreditCard = cRegExp.test(creditnum)
  return validCreditCard
}

export const validatorUrlValidator = val => {
  if (val === undefined || val === null || val.length === 0) {
    return true
  }
  /* eslint-disable no-useless-escape */
  const re = /^(http[s]?:\/\/){0,1}(www\.){0,1}[a-zA-Z0-9\.\-]+\.[a-zA-Z]{2,5}[\.]{0,1}/
  /* eslint-enable no-useless-escape */
  return re.test(val)
}

/* Definiendo reglas personalizadas */
export const validatorNameRegex = vName => {
  /* eslint-disable no-useless-escape */
  const nameRegExp = /^[a-zA-ZáéíóúÁÉÍÓÚäëïöüÄËÏÖÜàèìòùÀÈÌÒÙñÑ\s]+$/
  /* eslint-enable no-useless-escape */
  return nameRegExp.test(vName)
}

export const validatorZipRegex = vZip => {
  /* eslint-disable no-useless-escape */
  const zipRegExp = /^\d{5}$/
  /* eslint-enable no-useless-escape */
  return zipRegExp.test(vZip)
}

export const validatorAddressRegex = address => {
  /* eslint-disable no-useless-escape */
  const addressRegExp = /^[a-zA-ZáéíóúÁÉÍÓÚäëïöüÄËÏÖÜàèìòùÀÈÌÒÙñÑ0-9#/().',\-\s]+$/
  /* eslint-enable no-useless-escape */
  return addressRegExp.test(address)
}

export const validatorClubNameRegex = address => {
  /* eslint-disable no-useless-escape */
  const clubNameRegExp = /^[a-zA-ZáéíóúÁÉÍÓÚäëïöüÄËÏÖÜàèìòùÀÈÌÒÙñÑ0-9#().',\-_&¡!\s]+$/
  /* eslint-enable no-useless-escape */
  return clubNameRegExp.test(address)
}

export const validatorConfirmPassword = (value, { target }) => value === target

export const validatorFileSize = fileX => {
  const firstRule = fileX.size / 1024 / 1024 <= 2

  if (!firstRule) return false

  return fileX
}

export const validatorAlphaNumSpacesRegex = address => {
  /* eslint-disable no-useless-escape */
  const alphaNumSpacesRegExp = /^[a-zA-ZáéíóúÁÉÍÓÚäëïöüÄËÏÖÜàèìòùÀÈÌÒÙñÑ0-9\s]+$/
  /* eslint-enable no-useless-escape */
  return alphaNumSpacesRegExp.test(address)
}

export const validatorTagsRegex = vtag => {
  /* eslint-disable no-useless-escape */
  const tagsRegex = /^[a-z0-9\-]+$/
  /* eslint-enable no-useless-escape */
  return tagsRegex.test(vtag)
}
export const validatorFileType = fileX => {
  const secondRule = (
    fileX.type === 'image/jpeg'
  || fileX.type === 'image/jpg'
  || fileX.type === 'image/x-png'
  || fileX.type === 'image/png'
  || fileX.type === 'application/pdf'
  )
  if (!secondRule) return false
  return fileX
}

export const validatorImageAllowed = mimeType => {
  const secondRule = (
    mimeType === 'image/jpeg'
  || mimeType === 'image/jpg'
  || mimeType === 'image/x-png'
  || mimeType === 'image/png'
  )
  if (!secondRule) return false
  return true
}
